import http from 'services/http';
import { BreadcrumbsType, MetaDataType } from 'services/common';
import { AxiosResponse } from 'axios';

export interface CatergoryType {
  id: number;
  name: string;
  slug: string;
  colorCode: string;
  translations: Array<{
    locale: string;
    slug: string;
  }>;
  metaData?: MetaDataType;
}

export interface NewsCatergoryPageType {
  data: CatergoryType;
  breadcrumbs: BreadcrumbsType;
  newsPage: {
    slug: string;
    templateCode: string;
  };
}

export interface CategoriesResponse {
  data: Array<CatergoryType>;
  success: boolean;
  message: string;
}

export interface NewType {
  id: number;
  thumbnail?: string;
  title?: string;
  slug: string;
  publishedAt: string;
  description: string;
  content?: string;
  categories: Array<CatergoryType>;
  translations: Array<{ locale: string; slug: string | null }>;
  isVideo: number;
}

export interface NewDetailResponse {
  data: {
    data: NewType;
    metaData: MetaDataType;
    breadcrumbs: BreadcrumbsType;
  };
  message: string;
  success: boolean;
}

export interface NewsResponse {
  data: Array<NewType>;
  message: string;
  success: boolean;
  meta: {
    limit: number;
    page: number;
    total: number;
    totalPages: number;
  };
}

export interface NewsPageType {
  title: string;
  pageMetaData: {
    newsSectionNews0: {
      heading: string;
      seeMore: string;
    };
  };
  templateCode: string;
}

type FetchNewsTypesRequest = {
  page?: number;
  limit?: number;
  is_home?: boolean;
  is_video?: boolean;
  keyword?: string;
  category_ids?: string;
};

export type TypeCategoryNews = 1 | 2; // 1: default, 2: 6 conditions

type CategoriesNewsTypesRequest = {
  keyword?: string;
  type?: TypeCategoryNews 
};

type CategoryShuffleNewsTypesRequest = {
  categorySlug?: string;
  ignore_news_slug?: string 
};

export const getCategoriesNews = async (params: CategoriesNewsTypesRequest): Promise<CategoriesResponse> => {
  const { data } = await http.get('/news-categories', { params });
  return data;
};

export const getCategorySlugShuffleNews = async (params: CategoryShuffleNewsTypesRequest): Promise<NewsResponse> => {
  const { data } = await http.get(`/news/category-slug-shuffle/${params.categorySlug}`, { params: { ignore_news_slug: params.ignore_news_slug } });
  return data;
};

export const getNewsCategoryDetail = async (slugCategory: string) => {
  const { data } = await http.get<AxiosResponse<NewsCatergoryPageType>>(`/news-categories/slug/${slugCategory}`);
  return data;
};

export const getNewsList = async (params: FetchNewsTypesRequest): Promise<NewsResponse> => {
  const { data } = await http.get('/news', {
    params: {
      ...params,
      is_video: false,
    },
  });
  return data;
};

export const getNewsByCategory = async (slug: string): Promise<NewsResponse> => {
  const { data } = await http.get(`/news/category-slug/${slug}`, {
    params: {
      is_video: false,
    },
  });
  return data;
};

export const getNewsDetail = async (slug: string): Promise<NewDetailResponse> => {
  const { data } = await http.get(`/news/slug/${slug}`);
  return data;
};

export const getNewsToPreview = async (code: string): Promise<NewDetailResponse> => {
  const { data } = await http.get(`/news/preview/${code}`);
  return data;
};
